import { useState, useEffect } from "react";

const useLocation = () => {
  const [location, setLocation] = useState({ latitude: null, longitude: null });
  const [error, setError] = useState(null);
  const [trigger, setTrigger] = useState(0); // trigger state for re-fetching

  useEffect(() => {
    if (!navigator.geolocation) {
      setError("Geolocation is not supported by your browser");
      return;
    }

    navigator.geolocation.getCurrentPosition(
      (position) => {
        setLocation({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        });
      },
      (error) => {
        setError(error.message);
      }
    );
  }, [trigger]);

  const refetchLocation = () => {
    setTrigger((prev) => prev + 1); // Change trigger to re-run effect
  };

  return { location, error, refetchLocation };
};

export default useLocation;

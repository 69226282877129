import React, { useState, useEffect } from "react";
import Alert from "@mui/material/Alert";
import GroupCard from "../components/groups/GroupCard"; // Import the card component
import { getGroups } from "../services/GroupService";
import { useAuthCheck } from "../hooks/useAuthCheck";

export default function GroupsDisplay() {
  useAuthCheck();
  const [groups, setGroups] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchGroups = async () => {
      try {
        const data = await getGroups();
        setGroups(data.groups); // Asegúrate de ajustar este camino según la estructura de tus datos
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchGroups();
  }, []);

  if (loading) return <Alert severity="info">Cargando...</Alert>;
  if (error) return <Alert severity="error">Error: {error}</Alert>;

  return (
    <div className="container mx-auto p-4">
      <div className="flex flex-wrap -mx-2">
        {groups.map((group, index) => (
          <div className="w-full md:w-1/4 px-2 mb-4" key={index}>
            <GroupCard group={group} />
          </div>
        ))}
      </div>
    </div>
  );
}

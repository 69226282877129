import React from "react";
import {
  Typography,
  List,
  ListItem,
  ListItemText,
  Divider,
} from "@mui/material";

// Render the visits section
export const renderVisits = (visits) => {
  // Function to format DateTime
  const formatDateTime = (dateTime) => {
    const date = new Date(dateTime);
    return date.toLocaleString();
  };

  if (!visits || visits.length === 0) {
    return (
      <Typography variant="body2" className="text-gray-600">
        No visits recorded
      </Typography>
    );
  }

  return (
    <List className="divide-y divide-gray-200">
      {visits.map((visit, index) => (
        <React.Fragment key={index}>
          <ListItem>
            <ListItemText
              primary={formatDateTime(visit.dateTime)}
              secondary={visit.isDemo ? "Demo" : "Regular"}
            />
          </ListItem>
          {index < visits.length - 1 && <Divider />}
        </React.Fragment>
      ))}
    </List>
  );
};

import api from "./httpService";

async function getGroups() {
  try {
    const response = await api.get("/Group/GetGroups");
    return response.data;
  } catch (error) {
    console.error("Error fetching groups:", error);
  }
}

async function getClientStatus(groupId) {
  try {
    const response = await api.get(`/Group/GetClientStatus/${groupId}`);
    return response.data;
  } catch (error) {
    console.error(
      `Error fetching client statuses for group ${groupId}:`,
      error
    );
  }
}

export { getGroups, getClientStatus };

import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./views/Layout";
import Login from "./views/Login";
import NoPage from "./views/NoPage";
import GroupsDisplay from "./views/GroupsDisplay";
import GroupDetails from "./views/GroupDetails";
import ClientDetails from "./views/ClientDetails";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          {/* Insert RouteHandler here */}
          <Route
            index
            element={
              <>
                <GroupsDisplay />
              </>
            }
          />
          <Route path="groupDetails/:groupId" element={<GroupDetails />} />
          <Route path="login" element={<Login />} />
          <Route path="clientDetails/:clientId" element={<ClientDetails />} />
          <Route path="*" element={<NoPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;

import * as React from "react";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import FolderSharedIcon from "@mui/icons-material/FolderShared";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import MapIcon from "@mui/icons-material/Map";

const GroupDetails = ({ onMenuItemClick, activeContent }) => {
  // Mapping the activeContent to the value expected by BottomNavigation
  const getValueForActiveContent = (content) => {
    switch (content) {
      case "Add Client":
        return "addClients";
      case "Clients":
        return "clients";
      case "Map":
        return "map";
      default:
        return "";
    }
  };

  return (
    <BottomNavigation
      value={getValueForActiveContent(activeContent)}
      showLabels
    >
      <BottomNavigationAction
        label="Add clients"
        value="addClients"
        icon={<PersonAddIcon />}
        onClick={() => onMenuItemClick("Add Client")}
      />
      <BottomNavigationAction
        label="See clients"
        value="clients"
        icon={<FolderSharedIcon />}
        onClick={() => onMenuItemClick("Clients")}
      />
      <BottomNavigationAction
        label="Map"
        value="map"
        icon={<MapIcon />}
        onClick={() => onMenuItemClick("Map")}
      />
    </BottomNavigation>
  );
};

export default GroupDetails;

import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import { renderVisits } from "../components/clients/ClientVisits";
import { getClient, deleteClient } from "../services/ClientService";
import { getClientVisits } from "../services/VisitService";

const ClientDetails = () => {
  const { clientId } = useParams();
  const navigate = useNavigate();

  const [client, setClient] = useState(null);
  const [visits, setVisits] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [openDialog, setOpenDialog] = useState(false); // New state for managing dialog

  useEffect(() => {
    const fetchClient = async () => {
      try {
        setLoading(true);
        const clientData = await getClient(clientId);
        const visitsData = await getClientVisits(clientId);
        setClient(clientData);
        setVisits(visitsData);
      } catch (error) {
        console.error("Error fetching client:", error);
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchClient();
  }, [clientId]);

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  //TODO
  const onDelete = async () => {
    try {
      await deleteClient(clientId);
      navigate(-1);
    } catch (error) {
      console.error("Error deleting client:", error);
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;
  if (!client) return <div>No client found</div>;

  return (
    <Card className="m-4 bg-white shadow-lg">
      <CardContent>
        <Typography variant="h5" component="div">
          {client.name}
        </Typography>
        <Typography variant="body2">{client.description}</Typography>

        {/* Visits Section */}
        <div className="visits mb-4">
          <Typography variant="h6" component="div" className="mb-2">
            Visits:
          </Typography>
          {renderVisits(visits)}
        </div>
      </CardContent>
      <CardActions>
        <Button size="small" color="error" onClick={() => handleOpenDialog()}>
          Delete
        </Button>
        <Button size="small" onClick={() => navigate(-1)}>
          Return
        </Button>
      </CardActions>

      {/* Confirmation Dialog */}
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this client?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary" variant="text">
            Cancel
          </Button>
          <Button
            onClick={() => onDelete()}
            variant="contained"
            color="error"
            autoFocus
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
};

export default ClientDetails;

import React from "react";
import { Link } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const VisitModal = ({
  open,
  onClose,
  onAddNormalVisit,
  onAddDemoVisit,
  clientId,
}) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        Add a Visit/Demo
        <IconButton
          aria-label="close"
          onClick={onClose}
          style={{ position: "absolute", right: 8, top: 8 }} // Position the close button
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogActions>
        <Button onClick={onAddNormalVisit} variant="contained">
          Add Visit
        </Button>
        <Button onClick={onAddDemoVisit} variant="outlined">
          Add Demo
        </Button>
        <Link to={`/clientDetails/${clientId}`}>
          <Button>Details</Button>
        </Link>
      </DialogActions>
    </Dialog>
  );
};

export default VisitModal;

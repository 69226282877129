import React, { useState, useEffect, Suspense } from "react";
import { useParams } from "react-router-dom";
import { Alert } from "@mui/material";
import GroupMenu from "../components/groups/GroupMenu";
import CloseClients from "../components/clients/CloseClients";
import ClientCreateForm from "../components/clients/ClientCreateForm";
//import MapComponent from "../components/clients/MapComponent";
import { useAuthCheck } from "../hooks/useAuthCheck";
import useLocation from "../hooks/useLocation";
//To ensure the MapComponent is loaded only when it's needed
const MapComponent = React.lazy(() =>
  import("../components/clients/MapComponent")
);

const GroupDetails = () => {
  useAuthCheck();
  const [activeContent, setActiveContent] = useState("Clients");
  //Informes if the map has been loaded at least once
  const [mapLoaded, setMapLoaded] = useState(false);

  const { groupId } = useParams();
  const { location, error: locationError, refetchLocation } = useLocation();

  useEffect(() => {
    if (activeContent === "Map") {
      setMapLoaded(true);
    }
  }, [activeContent]);

  const handleMenuItemClick = (content) => {
    setActiveContent(content);
    refetchLocation();
  };

  //optimize the number of times the map is loaded in your React application to reduce costs associated with Google Maps API usage.
  //Instead of unmounting and remounting the MapComponent, we simply change its visibility. This means that the map is loaded only once and then shown or hidden based on the active content.
  const renderContent = () => {
    switch (activeContent) {
      case "Add Client":
        return <ClientCreateForm groupId={groupId} location={location} />;
      case "Clients":
        return <CloseClients groupId={groupId} location={location} />;
      case "Map":
        return null;
      default:
        return <div>Default Content</div>;
    }
  };

  return (
    <div className="flex flex-col">
      <div className="w-full">
        <GroupMenu
          activeContent={activeContent}
          onMenuItemClick={handleMenuItemClick}
        />
      </div>
      <div className="w-full">
        {locationError ? (
          <Alert severity="error" onClose={() => {}}>
            Error fetching location: {locationError}
          </Alert>
        ) : (
          <>
            {renderContent()}
            {mapLoaded && (
              <div
                style={{ display: activeContent === "Map" ? "block" : "none" }}
              >
                <Suspense fallback={<div>Loading Map...</div>}>
                  <MapComponent groupId={groupId} location={location} />
                </Suspense>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default GroupDetails;

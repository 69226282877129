import api from "./httpService";
import { setToken } from "./tokenService";

export const login = async (username, password) => {
  try {
    const response = await api.post("/Identity/Login", {
      usernameOrEmail: username,
      password: password,
    });

    // Check if the login was successful
    if (response.status === 200) {
      setToken(response.data.jwtToken);
      return response.data;
    } else {
      // Handle non-successful login attempts
      throw new Error("Login failed. Please check your username and password.");
    }
  } catch (error) {
    console.error("Login error:", error);
    throw error;
  }
};

// Add any other authentication related functions here

export default login;

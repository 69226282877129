import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import AdbIcon from "@mui/icons-material/Adb";
import { Link } from "react-router-dom";

export default function AppNavBar() {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          <AdbIcon sx={{ mr: 1 }} />

          <Link to="/">
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              Tredesit
            </Typography>
          </Link>
        </Toolbar>
      </AppBar>
    </Box>
  );
}

import axios from "axios";
import { getToken, isTokenExpired, removeToken } from "./tokenService";

const api = axios.create({
  baseURL: "https://mobile.tredesitmap.com/api", //"https://localhost:7115/api",
});

api.interceptors.request.use(
  async (config) => {
    if (isTokenExpired()) {
      // This is where you handle the token refresh or re-login
      // You might need to prompt the user or use a refresh token
      // For now, let's just remove the expired token
      removeToken();
      // Additional logic for handling token refresh should go here
    } else {
      const token = getToken();
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// api.interceptors.response.use(
//   (response) => response,
//   (error) => {
//     if (error.response && error.response.status === 401) {
//       // Redirect to login page
//       window.location = "/login"; // Or use a more React-friendly navigation method if available
//     }
//     return Promise.reject(error);
//   }
// );

export default api;

import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { isTokenExpired } from "../services/tokenService";

export const useAuthCheck = () => {
  const navigate = useNavigate();

  useEffect(() => {
    if (isTokenExpired()) {
      navigate("/login");
    }
  }, [navigate]);
};

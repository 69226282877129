import api from "./httpService";

async function getCloseClients(groupId, maxClients, latitude, longitude) {
  try {
    const response = await api.get("/Client", {
      params: {
        groupId,
        maxClients,
        latitude,
        longitude,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching close clients:", error);
  }
}

async function getClient(clientId) {
  try {
    const response = await api.get(`/Client/${clientId}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching client:", error);
  }
}

async function createClient(clientData) {
  try {
    const response = await api.post("/Client", clientData);
    return response.data;
  } catch (error) {
    console.error("Error creating client:", error);
  }
}

async function deleteClient(clientId) {
  try {
    const response = await api.delete(`/Client/Delete?clientId=${clientId}`);
    return response.data;
  } catch (error) {
    console.error("Error deleting client:", error);
  }
}

export { getCloseClients, getClient, createClient, deleteClient };

import api from "./httpService";

async function getClientVisits(clientId) {
  try {
    const response = await api.get(`/Visit/${clientId}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching visits:", error);
  }
}

async function addVisit(visitData) {
  try {
    console.log(visitData);
    const response = await api.post("/Visit/AddVisit", visitData);
    return response.data;
  } catch (error) {
    console.error("Error creating client:", error);
  }
}

export { getClientVisits, addVisit };

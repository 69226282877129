import axios from "axios";

const googleMapsGeocodingApiKey = "AIzaSyC9TMSiMqK684ctmBwCvJJmh7rBSS5fNMo";

const convertAddressToLocation = async (address) => {
  try {
    const response = await axios.get(
      `https://maps.googleapis.com/maps/api/geocode/json`,
      {
        params: {
          address: address,
          key: googleMapsGeocodingApiKey,
        },
      }
    );

    if (response.data.status === "OK") {
      const location = response.data.results[0].geometry.location;
      return { latitude: location.lat, longitude: location.lng };
    } else {
      throw new Error("Geocoding failed: " + response.data.status);
    }
  } catch (error) {
    console.error("Error in convertAddressToLocation:", error);
    throw error;
  }
};

export { convertAddressToLocation };

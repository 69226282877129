import React, { useEffect, useState, Fragment } from "react";
// Material-UI imports
import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Pagination,
  CircularProgress,
  TextField,
  InputAdornment,
} from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import SearchIcon from "@mui/icons-material/Search";

import { useAuthCheck } from "../../hooks/useAuthCheck";
import { getCloseClients } from "../../services/ClientService";
import { addVisit } from "../../services/VisitService";
import VisitModal from "./VisitModal";

const CloseClients = ({ groupId, location }) => {
  useAuthCheck();
  const [clients, setClients] = useState([]);
  const [error, setError] = useState(null);
  const [selectedClient, setSelectedClient] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [filterText, setFilterText] = useState("");

  //Pagination
  const [page, setPage] = useState(1);
  const itemsPerPage = 10;

  const handleOpenModal = (client) => {
    setSelectedClient(client);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedClient(null);
  };

  const handleFilterChange = (event) => {
    setFilterText(event.target.value);
  };

  const filteredClients = clients.filter((client) =>
    client.name.toLowerCase().includes(filterText.toLowerCase())
  );

  const handleAddVisit = async (isDemo) => {
    try {
      if (selectedClient) {
        await addVisit({ clientId: selectedClient.id, isDemo: isDemo });
        console.log("Visit added successfully");
      }
    } catch (error) {
      console.error("Error adding visit:", error);
    } finally {
      handleCloseModal();
    }
  };

  useEffect(() => {
    const fetchClients = async () => {
      if (location.latitude && location.longitude) {
        try {
          const response = await getCloseClients(
            groupId,
            100, //2 km
            location.latitude,
            location.longitude
          );
          setClients(response.clients);
        } catch (err) {
          setError("Error fetching close clients");
        } finally {
          setLoading(false); // End loading
        }
      }
    };

    fetchClients();
  }, [location, groupId]);

  return (
    <Box sx={{ width: "100%", bgcolor: "background.paper" }}>
      {loading ? (
        // Show CircularProgress when data is loading
        <Box sx={{ display: "flex", justifyContent: "center", p: 3 }}>
          <CircularProgress />
        </Box>
      ) : (
        <Fragment>
          {/* Input field for filtering */}
          <Box sx={{ display: "flex", justifyContent: "center", p: 2 }}>
            <TextField
              label="Filter by Name"
              variant="outlined"
              value={filterText}
              onChange={handleFilterChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          {/*Show list of clients if data is fetched and no location error*/}
          <List>
            {error ? (
              <ListItem>
                <ListItemText primary={error} />
              </ListItem>
            ) : (
              filteredClients
                .slice((page - 1) * itemsPerPage, page * itemsPerPage)
                .map((client) => (
                  <ListItem disablePadding key={client.id}>
                    <ListItemButton onClick={() => handleOpenModal(client)}>
                      <LocationOnIcon />
                      <ListItemText
                        primary={client.name}
                        secondary={`Distance: ${client.distance.toFixed(2)} km`}
                      />
                    </ListItemButton>
                  </ListItem>
                ))
            )}

            <VisitModal
              open={showModal}
              onClose={handleCloseModal}
              onAddNormalVisit={() => handleAddVisit(false)}
              onAddDemoVisit={() => handleAddVisit(true)}
              clientId={selectedClient ? selectedClient.id : null}
            />
          </List>
        </Fragment>
      )}
      {!loading && (
        // Show Pagination only when not loading
        <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
          <Pagination
            count={Math.ceil(clients.length / itemsPerPage)}
            page={page}
            onChange={(event, value) => setPage(value)}
          />
        </Box>
      )}
    </Box>
  );
};

export default CloseClients;

import { Outlet } from "react-router-dom";
import AppNavBar from "../components/layout/AppNavBar";

const Layout = () => {
  return (
    <>
      <AppNavBar></AppNavBar>

      <Outlet />
    </>
  );
};

export default Layout;

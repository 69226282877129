import React, { useState, useEffect } from "react";
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Alert,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { useAuthCheck } from "../../hooks/useAuthCheck";
import { getClientStatus } from "../../services/GroupService";
import { createClient } from "../../services/ClientService";
import { convertAddressToLocation } from "../../services/GeolocationService";

const meetingMethods = [
  { value: 0, label: "Trader" },
  { value: 1, label: "Direct Contact" },
  { value: 2, label: "Event" },
  { value: 3, label: "Referral" },
  { value: 4, label: "Online" },
  { value: 5, label: "Advertisement" },
  { value: 6, label: "Social Media" },
  { value: 7, label: "Networking" },
  { value: 8, label: "Cold Call" },
  { value: 9, label: "Trade Show" },
  { value: 10, label: "Seminar" },
  { value: 11, label: "Webinar" },
  { value: 12, label: "Public Relations" },
  { value: 13, label: "Community Event" },
  { value: 14, label: "Partner Referral" },
  { value: 15, label: "Word Of Mouth" },
  { value: 16, label: "Other" },
];

const visitTypes = [
  { value: 0, label: "Normal" },
  { value: 1, label: "Demo" },
  { value: 2, label: "Do not add visit, only create the client" },
];

const ClientCreateForm = ({ groupId, location }) => {
  useAuthCheck();
  const [useCurrentLocation, setUseCurrentLocation] = useState(true); // New state for location choice
  const [client, setClient] = useState({
    name: "",
    meetingMethod: 0,
    description: "",
    email: "",
    phone: "",
    clientStatusId: undefined,
    visitType: 1,
    groupId: groupId,
    addressInput: "",
    latitude: null,
    longitude: null,
  });
  const [clientStatuses, setClientStatuses] = useState([]);
  const [message, setMessage] = useState({ type: "", content: "" });

  useEffect(() => {
    const fetchClientStatuses = async () => {
      const fetchedStatuses = await getClientStatus(groupId);
      setClientStatuses(fetchedStatuses || []);
    };

    fetchClientStatuses();
  }, [groupId]);

  useEffect(() => {
    if (useCurrentLocation && location.latitude && location.longitude) {
      setClient((prevClient) => ({
        ...prevClient,
        latitude: location.latitude,
        longitude: location.longitude,
      }));
    } else {
      setClient((prevClient) => ({
        ...prevClient,
        latitude: null,
        longitude: null,
      }));
    }
  }, [location, useCurrentLocation]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setClient((prevClient) => ({
      ...prevClient,
      [name]: value,
    }));
  };

  const handleLocationCheckbox = (event) => {
    setUseCurrentLocation(event.target.checked);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      let updatedClient = { ...client };

      // If not using current location, convert the address to location
      if (!useCurrentLocation) {
        const location = await convertAddressToLocation(client.addressInput);
        if (!location || !location.latitude || !location.longitude) {
          throw new Error("Unable to retrieve location from address.");
        }
        updatedClient.latitude = location.latitude;
        updatedClient.longitude = location.longitude;
      }

      // Now, client object has either the current location or the location from the address
      await createClient(updatedClient);
      console.log("Client created successfully");
      setMessage({ type: "success", content: "Client created successfully!" });
    } catch (error) {
      console.error("Error creating client:", error);
      setMessage({
        type: "error",
        content: "Error creating client: " + error.message,
      });
    }
  };

  return (
    <div className="content p-4">
      <form onSubmit={handleSubmit} className="space-y-4">
        {/* Client Name */}
        <TextField
          label="Name"
          variant="outlined"
          name="name"
          value={client.name}
          onChange={handleChange}
          fullWidth
          className="w-full"
        />

        {/* Meeting Method */}
        <FormControl variant="outlined" fullWidth className="w-full">
          <InputLabel>Meeting Method</InputLabel>
          <Select
            name="meetingMethod"
            value={client.meetingMethod}
            onChange={handleChange}
            label="Meeting Method"
          >
            {meetingMethods.map((method, index) => (
              <MenuItem key={index} value={method.value}>
                {method.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* Client Status */}
        <FormControl variant="outlined" fullWidth className="w-full">
          <InputLabel>Client Status</InputLabel>
          <Select
            name="clientStatusId"
            value={client.clientStatusId}
            onChange={handleChange}
            label="Client Status"
          >
            {clientStatuses.map((status) => (
              <MenuItem key={status.id} value={status.id}>
                {status.statusName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* Visit Type */}
        <FormControl variant="outlined" fullWidth className="w-full">
          <InputLabel>Add Visit</InputLabel>
          <Select
            name="visitType"
            value={client.visitType}
            onChange={handleChange}
            label="Visit Type"
          >
            {visitTypes.map((type, index) => (
              <MenuItem key={index} value={type.value}>
                {type.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* Description */}
        <TextField
          label="Description"
          variant="outlined"
          name="description"
          value={client.description}
          onChange={handleChange}
          fullWidth
          margin="normal"
          multiline
          rows={4}
          className="w-full"
        />

        {/* Email */}
        <TextField
          label="Email"
          variant="outlined"
          name="email"
          value={client.email}
          onChange={handleChange}
          fullWidth
          margin="normal"
          type="email"
          className="w-full"
        />

        {/* Phone */}
        <TextField
          label="Phone"
          variant="outlined"
          name="phone"
          value={client.phone}
          onChange={handleChange}
          fullWidth
          margin="normal"
          className="w-full"
        />

        {/* Location Checkbox */}
        <FormControlLabel
          control={
            <Checkbox
              checked={useCurrentLocation}
              onChange={handleLocationCheckbox}
              color="primary"
            />
          }
          label="Use Current Location"
        />

        {/* Address Input - Shown when the checkbox is unchecked */}
        {!useCurrentLocation && (
          <TextField
            label="Address"
            variant="outlined"
            name="addressInput"
            value={client.addressInput}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
        )}

        {/* Client added message (error or success) */}
        {message.content && (
          <Alert severity={message.type} onClose={() => {}}>
            {message.content}
          </Alert>
        )}

        {/* Submit Button */}
        <Button
          variant="contained"
          color="primary"
          type="submit"
          className="w-full"
        >
          Create
        </Button>
      </form>
    </div>
  );
};

export default ClientCreateForm;
